(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.user.controller:ResetPasswordCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.authentication')
  .controller('ResetPasswordCtrl', ResetPasswordCtrl);

  function ResetPasswordCtrl($filter, $state, $mdToast, ResetPassword) {
    var vm = this;
    vm.ctrlName = 'ResetPasswordCtrl';
    vm.cardTitle = 'RECOVER_PASSWORD';
    vm.email = null;

    vm.sendRestoreEmail = function () {
      ResetPassword.save({email: vm.email}, function () {
        $state.go('authentication.login', {}, {reload: true});
        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')('SUCCESSFUL_PASSWORD_RESET'))
          .position('bottom left')
          .hideDelay(5000)
        );
      }, function () {
        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')('TOAST_ERROR_EMAIL'))
          .position('bottom left')
          .hideDelay(5000)
        );
      });
    };
  }
}());
